import React, { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePassiveGetCaseDetail } from '../../redux/caseDetail/caseDetailHooks';
import switchToMobile from '../../images/switchToMobile.svg';

import './SwitchToShort.scss';
import windowService from '../../services/windowService';
import { useLinkCreator } from '../../redux/hooks';

const SwitchToShort: FC = () => {
  const { t } = useTranslation();
  const [caseDetail] = usePassiveGetCaseDetail();
  const { displayHref } = useLinkCreator();

  const isSwitchToMobile = windowService.getIsDesktopScreen();

  return (
    <div className="SwitchToShort">
      <div className="SwitchToShort__icon">
        <img className="SwitchToShort__icon__image" src={switchToMobile} alt="" />
      </div>
      <div className="SwitchToShort__instructions">
        <div className="SwitchToShort__instructions__title">
          {t(isSwitchToMobile ? 'faq-use-app-on-mobile' : 'Switch_toComputerInstructionsTitle')}
        </div>
        <ol className="SwitchToShort__instructions__steps">
          <li className="SwitchToShort__instructions__steps__step">
            <Trans
              i18nKey="faq-go-to"
              values={{ caseUrl: displayHref }}
              components={{
                span: <span className="SwitchToShort__instructions__steps__step__link" />,
              }}
            />
          </li>
          {!!caseDetail?.dpocCaseId && (
            <li className="SwitchToShort__instructions__steps__step">
              <Trans
                i18nKey="faq-enter-case-id"
                values={{ caseId: caseDetail.dpocCaseId }}
                components={{
                  span: <span className="SwitchToShort__instructions__steps__step__link" />,
                }}
              />
            </li>
          )}
        </ol>
      </div>
    </div>
  );
};

export default SwitchToShort;
