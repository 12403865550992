import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_SELECTED_LOCALE } from './types/constants';
import devEnUS from './resources/transaltions/dev_en_US.json';

// *** Append supported languages for localization of dayjs ***
// *** NOTE: Need to use require to force, it is really imported even it is not directly used. ***
require('dayjs/locale/pt-br');
require('dayjs/locale/es');
require('dayjs/locale/fr-ca');
require('dayjs/locale/fr')

// *** Append localization resources ***
const resources: Record<string, { translation: Record<string, string> }> =
  process.env.NODE_ENV !== 'production'
    ? {
        'en-US': devEnUS, // used for local development only. So we can have new translations available asap for development.
      }
    : {};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: DEFAULT_SELECTED_LOCALE.localeCode,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((e) => {
    console.error('Translations initialization failed. ');
    console.error(e);
  });

export default i18n;
