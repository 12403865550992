import React, { type FC } from 'react';

import SubmissionProgress from './SubmissionProgress';
import './StepHeader.scss';

interface ApplicationStepsProps {
  stepNumber: number;
  totalIndices: number;
  title: React.ReactNode;
  description?: string;
}

const StepHeader: FC<ApplicationStepsProps> = ({ stepNumber, totalIndices, title, description }) => {
  return (
    <div className="StepHeader">
      <div>
        <div className="StepHeader__title">{title}</div>
        {!!description && <div className="StepHeader__description">{description}</div>}
      </div>
      <SubmissionProgress stepNumber={stepNumber} totalIndices={totalIndices} />
    </div>
  );
};

export default StepHeader;
