import React, { type FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import classNames from 'classnames';

import { APPLICATION_CONFIGURATION } from '../types/applicationConfiguration';
import Language from '../components/common/Language';
import { HelpButton, SwitchToMobileButton } from '../components';
import { useEnsureRequireSubmissionStarted, useEnsureSubmissionNotFinished } from '../redux/caseDetail/caseDetailHooks';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { useApplicationInit } from '../redux/hooks';
import { useEnsureCountryNotSelected, useEnsureCountrySelected } from '../redux/countrySelection/countrySelectionHooks';
import localizationService from '../services/localizationService';

import './Page.scss';
import Logo from '../components/common/Logo';

const Page: FC = () => {
  useEnsureRequireSubmissionStarted();
  useEnsureSubmissionNotFinished();
  useEnsureCountrySelected();
  useEnsureCountryNotSelected();
  const applicationInit = useApplicationInit();

  const direction = localizationService.getLanguageDirection();

  useEffect(() => {
    applicationInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider direction={direction}>
      <div className={classNames('Page', { rtl: direction === 'rtl' })}>
        <header className="Page__header">
          <div className="Page__header__title">
            <Logo className="Page__header__title__anthemLogo" />
            {APPLICATION_CONFIGURATION.Display_Application_Version && (
              <div className="Page__header__title__appVersion">
                ({}
                {APPLICATION_CONFIGURATION.Display_Application_Version})
              </div>
            )}
          </div>
          <div className="Page__header__controls">
            <HelpButton />
            <SwitchToMobileButton />
            <Language />
          </div>
        </header>

        <div className="Page__content">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Page;
