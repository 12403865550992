import { type Domain } from '../types/app';
import { CAROUSEL_MAX_SCREEN_WIDTH, COUNTRY_SEARCH_PARAM_NAMES } from '../types/constants';
import { type CountryLocaleDto } from '../types/dtos';

const getIsTouchEnabled: () => boolean = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  (navigator as any as { msMaxTouchPoints: number }).msMaxTouchPoints > 0;

const getIsCarouselScreen: () => boolean = () => window.screen.width <= CAROUSEL_MAX_SCREEN_WIDTH;
const getIsDesktopScreen: () => boolean = () => !getIsCarouselScreen() && !getIsTouchEnabled();

const getCountrySearchParam: () => string | null = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const countryCode = COUNTRY_SEARCH_PARAM_NAMES.reduce<null | string>(
    (acc, paramName) => acc ?? searchParams.get(paramName),
    null
  );

  // TODO - default country code / country selection dialog / country chosen by domain
  return countryCode;
};

const switchDomainByCountry = ({ merckCountry, countryCode }: CountryLocaleDto): boolean => {
  const { hostname, href } = window.location;
  if (hostname.includes('localhost')) return false;

  const changeDomainTo: Domain = merckCountry ? 'merck' : 'msd';

  const shouldNotRedirect = hostname.includes(`${changeDomainTo}.com`);

  if (shouldNotRedirect) return false;

  const currentDomain: Domain = changeDomainTo === 'msd' ? 'merck' : 'msd';
  const newUrl = new URL(href.replace(`${currentDomain}.com`, `${changeDomainTo}.com`));

  if (!getCountrySearchParam()) {
    newUrl.searchParams.append(COUNTRY_SEARCH_PARAM_NAMES[0], countryCode);
  }

  window.location.href = newUrl.toString();
  return true;
};

const getIsMerckDomain = (): boolean => {
  const { hostname } = window.location;
  return hostname.includes('merck.com');
};

const windowService = {
  getIsTouchEnabled,
  getIsCarouselScreen,
  getIsDesktopScreen,
  getIsMerckDomain,
  getCountrySearchParam,
  switchDomainByCountry,
};

export default windowService;
