import React, { type FC } from 'react';

import msdLogo from '../../images/msdLogo.png';
import merckLogo from '../../images/merckLogo.png';
import { useMerckDomain } from '../../redux/hooks';

interface LogoProps {
  className: string;
}

const Logo: FC<LogoProps> = ({ className }) => {
  const { isMerckDomain } = useMerckDomain();

  const logoSrc = isMerckDomain ? merckLogo : msdLogo;
  const companyName = isMerckDomain ? 'Merck' : 'MSD';

  return <img className={className} src={logoSrc} alt={`${companyName} Inventing for Life`} />;
};

export default Logo;
