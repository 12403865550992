import i18n from '../i18n';
import dayjs from 'dayjs';
import { APPLICATION_DEFAULT_TITLE, SERVER_ENGLISH_LOCALE } from '../types/constants';
import { type LocaleDto, type PublicTranslationDto } from '../types/dtos';

import hotfix from '../resources/transaltions/hotfix.json';

const getLanguageDirection = (): 'rtl' | 'ltr' => i18n.dir(i18n.language);

const changeLanguage = async (languageCode: string): Promise<void> => {
  setDayjsLocale(languageCode)
  await i18n.changeLanguage(languageCode);

  document.title = i18n.exists('LanguageSelection_photoAppName')
    ? i18n.t('LanguageSelection_photoAppName')
    : i18n.exists('Application_title')
    ? i18n.t('Application_title')
    : APPLICATION_DEFAULT_TITLE;
};

const getLocaleToUse = (languageCode: string): string => {
  if (languageCode.startsWith('es')) {
    return 'es'
  } else if (languageCode === 'fr-CA') {
    return languageCode
  } else if (languageCode.startsWith('fr')) {
    return 'fr'
  } else {
    return languageCode
  }
}

const setDayjsLocale = (languageCode: string):  void => {
  // TODO: remove this hotfix, start reading days translations from BE https://issues.merck.com/browse/PQCPIMS-741
  const localeToUse = getLocaleToUse(languageCode)

  dayjs.locale(localeToUse);
};

const parseLanguage: (locale: string) => string = (locale) => locale.split('-')[0];

const getUserLanguages = (): string[] => navigator.languages?.map((loc) => parseLanguage(loc)) ?? [];

const getPreferredLocaleCode = (locales: LocaleDto[]): string | undefined => {
  const userLanguages = getUserLanguages();
  const languagesMap = locales.reduce((acc: Record<string, LocaleDto>, locale) => {
    acc[parseLanguage(locale.localeCode)] = locale;
    return acc;
  }, {});
  const preferredLanguage = userLanguages.find((userLanguage) => languagesMap[userLanguage]);
  return preferredLanguage ? languagesMap[preferredLanguage].localeCode : undefined;
};

const translateToEnglish: (key: string) => string = (key) => i18n.t(key, { lng: SERVER_ENGLISH_LOCALE });

const doAddTranslations: (translations: PublicTranslationDto) => void = (translations) => {
  Object.entries(translations).forEach(([locale, resources]) => {
    i18n.addResourceBundle(locale, 'translation', resources, true, true);
  });
};

const addTranslations: (translations: PublicTranslationDto) => Promise<void> = async (translations) => {
  doAddTranslations(translations);
  await changeLanguage(i18n.language);
};

const localizationService = {
  changeLanguage,
  getPreferredLocaleCode,
  translateToEnglish,
  addTranslations,
  getLanguageDirection,
};

// Hotfix for the issue, that user access the link to non-existing case (no translations are loaded that time, so we add them here)
doAddTranslations(hotfix);

export default localizationService;
